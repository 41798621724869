import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState } from 'react';
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'

const BudgetModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState("")
    const [field02, setField02] = useState("")
    const [field03, setField03] = useState("")
    const [field04, setField04] = useState("")
    const [field05, setField05] = useState("")
    const [field06, setField06] = useState("")
    const [field07, setField07] = useState("")
    const [field08, setField08] = useState("")
    const [field09, setField09] = useState("")
    const [field10, setField10] = useState("")
    const [field11, setField11] = useState("")
    const [field12, setField12] = useState("")
    const [field13, setField13] = useState("")
    const [field14, setField14] = useState("")
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};

    const toggleModal = () => {
        setShowModal(showModal => !showModal);
        closeModal()
    }

    const updateItem = () => {
        const result = field13.split(/\r?\n/);
        if (result.length > 7) {
            alert(`${"More than 7 lines in Invoice Ref."}\n\n${"Note: Only first 7 rows will be retained."}`)
        }
        updateData.FirstName = field01;
        updateData.LastName = field02;
        updateData.OrganistationName = field03;
        updateData.FA1 = result[0];
        updateData.FA2 = result[1];
        updateData.FA3 = result[2];
        updateData.FA4 = result[3];
        updateData.FA5 = result[4];
        updateData.FA6 = result[5];
        updateData.FA7 = result[6];
        updateData.include_timeslips = field11;
        updateData.url = field12;

        base.update("ManageData/contacts/upd/" + id, updateData).then((res) => {
            setSaveItem(new Date().getTime());
            toggleModal()
        })
    }

    const handleChange = (e) => {
        if (e.target.name === 'contactName') {
            setField01(e.target.value);
        }

        if (e.target.name === 'FA8') {
            setField13(e.target.value);
        }

        if (e.target.name === 'name') {
            setField03(e.target.value);
        }
        if (e.target.name === 'po_ref') {
            setField14(e.target.value);
        }
        if (e.target.name === 'FA1') {
            setField04(e.target.value);
            // console.log(e.target.value);
            setField13(
                e.target.value + '\r' + field05 + '\r' + field06 + '\r' + field07 + '\r' + field08 + '\r' + field09 + '\r' + field10
            )
        }
        if (e.target.name === 'FA2') {
            setField05(e.target.value);
            setField13(
                field04 + '\r' + e.target.value + '\r' + field06 + '\r' + field07 + '\r' + field08 + '\r' + field09 + '\r' + field10
            )
        }
        if (e.target.name === 'FA3') {
            setField06(e.target.value);
            setField13(
                field04 + '\r' + field05 + '\r' + e.target.value + '\r' + field07 + '\r' + field08 + '\r' + field09 + '\r' + field10
            )
        }
        if (e.target.name === 'FA4') {
            setField07(e.target.value);
            setField13(
                field04 + '\r' + field05 + '\r' + field06 + '\r' + e.target.value + '\r' + field08 + '\r' + field09 + '\r' + field10
            )
        }
        if (e.target.name === 'FA5') {
            setField08(e.target.value);
            setField13(
                field04 + '\r' + field05 + '\r' + field06 + '\r' + field07 + '\r' + e.target.value + '\r' + field09 + '\r' + field10
            )
        }
        if (e.target.name === 'FA6') {
            setField09(e.target.value);
            setField13(
                field04 + '\r' + field05 + '\r' + field06 + '\r' + field07 + '\r' + field08 + '\r' + e.target.value + '\r' + field10
            )
        }
        if (e.target.name === 'FA7') {
            setField10(e.target.value);
            setField13(
                field04 + '\r' + field05 + '\r' + field06 + '\r' + field07 + '\r' + field08 + '\r' + field09 + '\r' + e.target.value
            )
        }

        if (e.target.name === 'include_timeslips') {
            setField11(e.target.value);

        }


        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    useEffect(() => {
        const getData = () => {
            const getFormData = async () => {
                await base.getAll("ManageData/contacts/" + id).then((res) => {
                    setField01(res.contactName);
                    setField02(res.name);
                    setField04(res.fA1);
                    // if (res.fA1 === '') { console.log("blank") }
                    // if (res.fA1 === null) { console.log(null) }
                    setField05(res.fA2);
                    setField06(res.fA3);
                    setField07(res.fA4);
                    setField08(res.fA5);
                    setField09(res.fA6);
                    setField10(res.fA7);
                    // const join = `${ first } ${ second } `;
                    var data = ""
                        + (res.fA1 !== '' ? res.fA1 : "")
                        + '\n' + (res.fA2 !== '' ? res.fA2 : "")
                        + '\n' + (res.fA3 !== '' ? res.fA3 : "")
                        + '\n' + (res.fA4 !== '' ? res.fA4 : "")
                        + '\n' + (res.fA5 !== '' ? res.fA5 : "")
                        + '\n' + (res.fA6 !== '' ? res.fA6 : "")
                        + '\n' + (res.fA7 !== '' ? res.fA7 : "")

                    setField13(
                        data
                    )
                    setField11(res.include_timeslips);
                    setField12(res.url)
                    setField14(res.contract_po_reference)
                    setLoading(false)
                })
            }

            getFormData();
        }
        getData()
    }, [id])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Edit Contact</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Contact</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="firstName" id="firstName" placeholder="" defaultValue={field01} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Project</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="name" id="name" placeholder="" defaultValue={field02} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">PO Reference</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="po_ref" id="po_ref" placeholder="" defaultValue={field14} onChange={handleChange} />
                                    </Col>
                                </Row>
                                {/* <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA1*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA1" id="FA1" placeholder="" value={field04} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA2*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA2" id="FA2" placeholder="" value={field05} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA3*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA3" id="FA3" placeholder="" value={field06} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA4*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA4" id="FA4" placeholder="" value={field07} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA5*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA5" id="FA5" placeholder="" value={field08} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA6*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA6" id="FA6" placeholder="" value={field09} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">FA7*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="FA7" id="FA7" placeholder="" value={field10} onChange={handleChange} />
                                    </Col>
                                </Row> */}
                                <Row className="mb-2">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Invoice Ref</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="textarea" style={{ height: "140px" }} className="input-text-locked" name="FA8" id="FA8" placeholder="" value={field13} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Invoice Detail*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="include_timeslips" id="include_timeslips" className='input-text' value={field11} onChange={handleChange} >
                                            <option value={null} className='inputselect'>
                                            </option>
                                            <option className='input-text' value="billed_grouped_by_single_timeslip">
                                                Billed grouped by single timeslip
                                            </option>
                                            <option className='input-text' value="billed_grouped_by_timeslip">
                                                Billed grouped by timeslip
                                            </option>
                                            <option className='input-text' value="billed_grouped_by_timeslip_task">
                                                Billed grouped by timeslip task
                                            </option>
                                            <option className='input-text' value="billed_grouped_by_timeslip_task">
                                                Billed grouped by timeslip task
                                            </option>
                                            <option className='input-text' value="billed_grouped_by_timeslip_date">
                                                Billed grouped by timeslip date
                                            </option>
                                        </Input>
                                        {/* <select name="timeslips" id="timeslips">
                                            <option value="null"></option>
                                            <option value="billed_grouped_by_single_timeslip">billed grouped by single timeslip</option>
                                            <option value="billed_grouped_by_timeslip">billed grouped by timeslip</option>
                                            <option value="billed_grouped_by_timeslip_task">billed grouped by timeslip task</option>
                                            <option value="billed_grouped_by_timeslip_date">billed grouped by timeslip date</option>
                                        </select> */}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button>
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default BudgetModal
