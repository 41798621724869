import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineProject } from 'react-icons/ai'
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import Spinner from '../../shared/loading'
import EditModal from './ccReconModal'
import add from '../../../assets/icons/addlrg24.svg';

const Projects = () => {

    const gridRef = useRef();
    const [budgetData, setBudgetData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);

    // const formatNumber = useCallback((number) => {
    //     // this puts commas into the number eg 1000 goes to 1,000,
    //     return (number)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    // }, []);
    // const currencyFormatter = useCallback((params) => {
    //     return formatNumber(params.value);
    //     // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "heading",
                field: "heading",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "subHeading",
                field: "subHeading",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 350
            },
            // {
            //     headerName: "Justification",
            //     field: "reason",
            //     // cellClass: "alignCenter widthhhh",
            //     suppressMovable: true,
            //     maxWidth: 350
            // },
            // {
            //     headerName: "Period",
            //     field: "period",
            //     // cellClass: "alignCenter widthhhh",
            //     suppressMovable: true,
            //     maxWidth: 200
            // },
            // {
            //     headerName: "Value",
            //     field: "value",
            //     // cellClass: "alignCenter widthhhh",
            //     suppressMovable: true,
            //     filter: 'agNumberColumnFilter',
            //     type: 'numericColumn',
            //     valueFormatter: currencyFormatter,
            //     maxWidth: 200
            // },

            // {
            //     headerName: "Additional Info 1",
            //     field: "reason1",
            //     // cellClass: "alignCenter widthhhh",
            //     suppressMovable: true,
            //     maxWidth: 350
            // },
            // {
            //     headerName: "Additional Info 2",
            //     field: "reason2",
            //     // cellClass: "alignCenter widthhhh",
            //     suppressMovable: true,
            //     maxWidth: 350
            // },
            {
                headerName: "id",
                field: "iD",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, []);

    const openModal = () => {
        setShowModal(!showModal)
        // //console.log(showModal)
    }
    const openModal1 = () => {
        setBudgetID("new")
        setShowModal(!showModal)

        // //console.log(showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("ManageData/project").then((res) => {
                setBudgetData(res);
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])

    }, [saveItem])

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].id);

            openModal();
            // console.log(selectedRows[0].id)
            // setTimeout(() => {
            //     //console.log(budgetID);
            // }, [5000])

        }
        // document.querySelector('#selectedRows').innerHTML =
        //     selectedRows.length === 1 ? selectedRows[0].id : '';
    };

    return (
        <>

            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="12" sm="9" className="page-header">
                        <AiOutlineProject size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">Client Projects</span>
                    </Col>
                    {/* <span > */}
                    <Col xs="12" sm="3" className="text-end pe-4">
                        <Button onClick={openModal1} size="sm" color="secondary" className="btn-text" style={{ height: "38px" }} ><img src={add} alt="add" /></Button>
                    </Col>
                    {/* </span> */}
                </Row>
            </CardHeader>
            <div className="container-fluid">
                <Row >
                    <Col xs="12">
                        {expLoading ?
                            <CardBody style={{ height: "750px" }}>
                                <div className="row align-items-center">
                                    <div className="text-center" >
                                        <Spinner />
                                    </div>
                                </div>
                            </CardBody>
                            :
                            <>
                                {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                <CardBody style={{ height: "680px" }}>
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={budgetData}
                                            columnDefs={budgetColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            // onGridReady={onGridReady}
                                            rowSelection={'single'}
                                            // rowSelection={'single'}
                                            paginationPageSize={20}
                                            // gridOptions={gridOptions}
                                            onRowDoubleClicked={onSelectionChanged}
                                        // onGridReady={onGridReady}
                                        // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                        // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                        // onRowDoubleClicked=(()=> editRow())
                                        >
                                        </AgGridReact>
                                    </div>
                                </CardBody>
                            </>

                        }

                    </Col>

                </Row >
            </div>
            {showModal && <EditModal id={budgetID} closeModal={closeModal} showModalForm={true} setSaveItem={setSaveItem} />}
            {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

        </>
    )
}

export default Projects
