import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineGroup } from 'react-icons/ai';
// import refresh from '../../../assets/icons/refresh24.svg';
import download from '../../../assets/icons/download24.svg';
import ifilter from '../../../assets/icons/filter24.svg';
import ifilterFill from '../../../assets/icons/filterfill24.svg';
import remove from '../../../assets/icons/remove24.svg';
import removeLrg from '../../../assets/icons/remove32.svg';
import Select from 'react-select'
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button, Card, Input, InputGroup, Tooltip } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import Spinner from '../../shared/loading'
// import EditModal from './vatModal'

const Transactions = () => {

    const gridRef = useRef();
    const [data, setData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [filter, setFilter] = useState(false);
    const [filter1, setFilter1] = useState([]);
    const [filter2, setFilter2] = useState([]);
    const [filter3, setFilter3] = useState('');
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    // const [inputValue1, setInputValue1] = useState([]);
    const [options, setOptions] = useState([]);
    // const [saveItem, setSaveItem] = useState();
    const [expLoading, setExpLoading] = useState(true);
    // const [budgetID, setBudgetID] = useState();
    // const [showModal, setShowModal] = useState(false)
    // const [toggleModal, setToggleModal] = useState(false)
    const [showFilter, setShowFilter] = useState(false);
    // const [selectedValue, setSelectedValue] = useState("");

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    // const formatNumber = useCallback((number) => {
    //     // this puts commas into the number eg 1000 goes to 1,000,
    //     return (number)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    // }, []);
    // const currencyFormatter = useCallback((params) => {
    //     return formatNumber(params.value);
    //     // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // }, [formatNumber]);



    // const closeModal = () => {
    //     setShowModal(showModal => false)
    //     // setExpLoading(true)
    //     setToggleModal(!toggleModal)
    // }


    // const isFirstColumn = useCallback((params) => {
    //     if (invCreate) {
    //         var displayedColumns = params.columnApi.getAllDisplayedColumns();
    //         var thisIsFirstColumn = displayedColumns[0] === params.column;
    //         return thisIsFirstColumn;
    //     }

    // }, [invCreate]);


    // const openModal = () => {
    //     setShowModal(!showModal)
    //     // //console.log(showModal)
    // }



    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([

            {
                headerName: "Description",
                field: "description",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Group Desc",
                field: "group_description",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Tax Reporting Name",
                field: "tax_reporting_name",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Auto Tax Rate",
                field: "auto_sales_tax_rate",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            // {
            //     headerName: "Access",
            //     field: "access",
            //     maxWidth: 100,
            //     checkboxSelection: true
            // },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            }
        ]
        );
    }, []);

    // function boolRenderer(params) {
    //     return `<input type="checkbox" ${params.value ? 'checked' : ''} />`;
    // }


    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {

        const getCategories = async () => {
            await base.getAll("Report/categories").then((catres) => {
                setOriginData(catres);
                setData(catres);
                let detailArr = [];
                catres.map(element => {
                    let dropDownEle = { value: element["description"], label: element["description"] };
                    detailArr.push(dropDownEle);
                    return 1;
                });
                // let dropDownEle = 
                // if (user === '10') {
                //     detailArr.push({ value: "0", label: "All" })
                //     // //console.log(detOptionsailArr)
                //     // setSelectedValue("3")
                // }
                setOptions(detailArr)

            });
        }
        // getContacts();
        getCategories();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])
    }, [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const selectChange = (e, id) => {
        // console.log(e.value.map(x => {
        //     return {
        //         label: x.label,
        //         value: x.value
        //     }

        // }))
        // console.log(inputValue)
        var fName = id.name
        // if (fName === 'drpTax') {
        //     setFilter1(Array.isArray(e) ? e.map(x => x.value) : []);
        //     setInputValue1(e)

        //     // setFilter(true);
        // }
        if (fName === 'drpCat') {
            // setInputValue(e.value);
            setFilter2(Array.isArray(e) ? e.map(x => x.value) : []);
            setInputValue(e)
        }
        // filterData();
        // // console.log(filterOptions)
        // if (Array.isArray() === true && filterOptions.length <= 0) {
        //     // console.log('rest')
        //     setData(originData)
        //     setFilter(false);
        // } else {
        //     setData(originData.filter(f => filterOptions.some(item => item.includes(f.cat_description))));
        //     setFilter(true);
        // }
    }

    useEffect(() => {
        const filterData = () => {
            var xData = originData;
            // console.log(xData);
            // console.log(filter1.length);
            // console.log(filter2);
            // var z = 0
            // var y = 20

            // console.log(':' + z.toFixed() + ':', ':' + y.toFixed(2) + ':')
            // console.log(filter1)

            if (filter1.length > 0) {
                // xData = xData.filter(f => filter1.some(item => item.includes(f.tax_rate)));
                // console.log(xData.tax_rate.toString())
                // xData = xData.filter(f => filter1.some(item => item.includes(f.tax_rate)));
                // xData = xData.filter(f => f.tax_rate.toFixed(2).includes(filter1.map(function (item) {
                //     return item;
                // })));
                xData = xData.filter(f => filter1.some(item => item.includes(f.string_tax_rate)));
                setFilter(true);
            }

            if (filter2.length > 0) {
                xData = xData.filter(f => filter2.some(item => item.includes(f.description)));
                setFilter(true);
            }

            if (filter3 !== '') {

                xData = xData.filter(f => {
                    return (
                        f.description.toUpperCase().includes(filter3.toUpperCase())
                        || (f.tax_reporting_name !== null && f.tax_reporting_name.toUpperCase().includes(filter3.toUpperCase()))
                    )
                }
                )
                // })
                // xData = xData.filter(f => f.description.toUpperCase().includes(filter3.toUpperCase()))
                setFilter(true);

            }


            if (filter1.length === 0 && filter2.length === 0 && filter3 === '') {
                // console.log('filter false')
                setFilter(false);
            }

            setData(xData);

        }
        filterData();
    }, [filter1, filter2, filter3, originData]);



    const handleChange = (e) => {
        // console.log(e.target.value)
        var searchInput = e.target.value;
        // console.log(searchInput);
        if (searchInput !== '') {
            setFilter3(searchInput);
        }
        else {
            // setFilter(false);
            setFilter3('');
        }
        // setData(originData.filter(f => `${searchInput}`.some(item => item.includes(f.cat_description))));
        // setData(data.filter(f => f.cat_description.indexOf(e.value)))
    }

    const closeFilter = () => {
        setTooltipOpen(false);
        setShowFilter(false);
        setFilter(false);
        setData(originData);
        setInputValue([]);
        // setInputValue1([]);
        setFilter1([]);
        setFilter2([]);
        setFilter3('');
    }


    return (
        <>
            {/* {!displayModal ? */}
            <>

                <CardHeader className="pt-3 pb-3">
                    <Row>
                        <Col xs="12" sm="9" className="page-header">
                            <AiOutlineGroup size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                            <span className="section-heading">Tax Categories</span>
                        </Col>
                        {/* <span > */}

                        <Col xs="12" sm="3" className="text-end pe-4">
                            <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => setShowFilter(!showFilter)}>
                                {filter ?
                                    // <AiFillFilter size={30} className="mb-1" color="#8bc5c5" />
                                    <img src={ifilterFill} alt="filter applied" />
                                    :
                                    // <AiOutlineFilter size={30} className="mb-1" color="#8bc5c5" />
                                    <img src={ifilter} alt="filter" />

                                }
                                {/* <img src={filter} alt="filter" /> */}
                            </Button>
                            <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => onBtnExport()}>
                                <img src={download} alt="download" />
                            </Button>
                        </Col>
                        {/* </span> */}
                    </Row>
                </CardHeader>
                <div className="container-fluid">
                    <Row>
                        <Col xs="12">
                            {expLoading ?
                                <CardBody style={{ height: "750px" }}>
                                    <div className="row align-items-center">
                                        <div className="text-center" >
                                            <Spinner />
                                        </div>
                                    </div>
                                </CardBody>
                                :
                                <>

                                    {showFilter &&
                                        <Card className="mt-3 ms-3 me-3">
                                            <CardBody style={{ backgroundColor: "#F2F2F2" }}>

                                                <div className="row align-items-center">
                                                    <Col xs="3">
                                                        <InputGroup>
                                                            <Input type="text" value={filter3} className="input-text-filter" name="keyword" id="keyword" placeholder="Filter by keyword" onChange={handleChange} />
                                                            <Button size="sm" color="" className="btn-text" onClick={() => setFilter3('')}>
                                                                <img src={remove} alt="remove" />
                                                            </Button>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs="3">
                                                        <Select className='input-text-filter' options={options} isMulti={true} onChange={selectChange} name="drpCat"
                                                            // className="text-lrf"
                                                            // options={users}
                                                            value={inputValue}
                                                            defaultValue={inputValue}
                                                            placeholder={"Category..."} />
                                                    </Col>
                                                    {/* <Col xs="3">
                                                    <Select className='input-text-filter' closeMenuOnSelect={false} options={options1} isMulti={true} onChange={selectChange} name="drpTax"
                                                        // className="text-lrf"
                                                        // options={users}
                                                        defaultValue={inputValue1}
                                                        value={inputValue1}

                                                        placeholder={"Tax Rate..."} />
                                                </Col> */}
                                                    <Col className='text-end'>
                                                        <Button size="sm" color="" className="btn-text" id="TooltipExample" onClick={() => closeFilter(false)}>
                                                            <img src={removeLrg} alt="remove" />
                                                            <Tooltip
                                                                // {...args}
                                                                isOpen={tooltipOpen}
                                                                target="TooltipExample"
                                                                toggle={toggle}
                                                                placement={'bottom'}
                                                            >
                                                                Close and reset filters
                                                            </Tooltip>
                                                        </Button>
                                                    </Col>

                                                </div>
                                            </CardBody>
                                        </Card>


                                    }

                                    {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                    <CardBody style={{ height: "680px" }}>
                                        <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={data}
                                                columnDefs={budgetColDef}
                                                overlayNoRowsTemplate="No Records Found"
                                                defaultColDef={gridDefaultColDef}
                                                pagination={true}
                                                // onGridReady={onGridReady}
                                                rowSelection={'single'}
                                                // rowSelection={'single'}
                                                paginationPageSize={20}
                                            // gridOptions={gridOptions}
                                            // onRowDoubleClicked={onSelectionChanged}
                                            // onGridReady={onGridReady}
                                            // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                            // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                            // onRowDoubleClicked=(()=> editRow())
                                            >
                                            </AgGridReact>
                                        </div>
                                    </CardBody>
                                </>

                            }

                        </Col>

                    </Row >
                </div>

                {/* {showModal && <BudgetModal closeModal={closeModal} showModalForm={true} />} */}
                {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}
                {/* {showModal && <EditModal id={budgetID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />} */}


            </>

        </>

    )
}

export default Transactions
