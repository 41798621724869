import React, { useEffect } from 'react'
// import Slider from 'react-slick'
import { Row, Col, Button } from 'reactstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/Main.css'
import { Link } from 'react-router-dom'
// import HomepageCarousel from "./homepageCarousel";
import ServicesItems from './servicesItems.js';
import ProjectsItems from "./projectsItems.js";
import image9 from '../../assets/images/image7_small_res.jpg'
import TextOnImage from './textOnImage.js';

export const Homepage = () => {

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.div-container');
            elements.forEach((element) => {
                const positionFromTop = element.getBoundingClientRect().top;
                const threshold = window.innerHeight - 100;
                console.log(positionFromTop, threshold)
                if (positionFromTop < threshold) {
                    element.classList.add('visible');
                } else {
                    element.classList.remove('visible');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        document.title = 'Software Development Consultancy in Harlow, Essex | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Professional software services provider that specializes in delivering fully managed solutions based on Microsoft .NET, React and JavaScript.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software development, web design, data integration, software modernization, database management, automation, business applications, responsive websites, data consolidation, technology updates, process automation';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaRobots);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);
    const subheading = (
        <span className="content-heading">
            Unlock Your Potential with <span className="emph-brush font-orange">Custom IT Solutions</span>
        </span>
    )
    const style = {
        border: "solid 1px #003a3a"
    }
    return (
        <>
            <div>
                <TextOnImage imageUrl={image9} heading="Book your FREE consultation now and discover the IT systems that will drive your growth."
                    subheading={subheading} fromHome={true} altText={"Laptop displaying interactive dashboard"}
                />

            </div>

            <div className="">
                <div className="section pt-5">
                    <div className="container-large">
                        <div className="content-heading font-darkteal ">
                            Services
                        </div>
                        <div className="content-body font-midteal pb-5">
                            We have over 35 years experience in software development and IT consultancy
                        </div>

                        <ServicesItems />

                        <Row className="pb-4 mb-3">
                            <Col className="text-center">
                                <Link to="/services">
                                    <Button className="main-button btn-steel-blue" size="lg">View all services</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>



                <div className="section-dark">
                    <div className="section pt-4">
                        <div className="container-large">

                            <div className="content-heading font-white ">
                                Projects
                            </div>
                            <div className="content-body font-topaz pb-5">
                                A proven track record in delivering cost-effective and reliable business solutions
                            </div>

                            <ProjectsItems style={style} />


                        </div>
                        <Row className="pb-5 mt-4">
                            <Col className="text-center">
                                <Link to="/projects">
                                    <Button className="main-button btn-steel-blue" size="lg">View all projects</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <div className="section pt-5">
                        <div className="container-large">

                            <div className="section-heading">
                                Services
                            </div>
                            <div className="section-subheading pb-5">
                                We have over 30 years experience in software development and business support.
                            </div>
                            <Row className="text-center pb-5">
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineUser size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Consulting</h5>
                                    <p className="text-center sub-text">We work with companies to set out their goals and help them put the right IT strategy in place.</p>
                                </Col>
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineCode size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Web Development</h5>
                                    <p className="text-center sub-text">We have designing and developing web applicaitons for over 20 years. We have expereince in the latest technologies including ASP.NET Core, React, Bootstap, Single Sign-On (SSO).</p>
                                </Col>
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineApi size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Data Integration</h5>
                                    <p className="text-center sub-text">Whether developing bespoke APIs or linking into exisiting applications, we have a proven track record delivering efficient data integration processes.</p>
                                </Col>
                            </Row>
                            <Row className="text-center pb-5">
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineSetting size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Spreadsheet Automation</h5>
                                    <p className="text-center sub-text">We have worked on numerous projects that involve improving/integrating MS Excel spreadsheets.</p>
                                </Col>
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineDatabase size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Database Management</h5>
                                    <p className="text-center sub-text">We have vast experience in working with databases such as Oracle, SQL Server, MySQL and PostgreSQL.</p>
                                </Col>
                                <Col md="4">
                                    <span className="">
                                        <AiOutlineCloud size={100} color="#0275d8" />
                                    </span>
                                    <h5 className="text-center my-3 head-text">Hosting</h5>
                                    <p className="text-center sub-text"> We provide Web/Email/VPS hosting services and have packages available to suit any requirements and/or budget.</p>
                                </Col>
                            </Row>
                        </div>
                    </div> */}
            </div>

        </>

    );
}

export default Homepage
