import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState } from 'react';
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'

const TasksModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState()
    const [field02, setField02] = useState()
    const [field03, setField03] = useState()
    const [field04, setField04] = useState()
    const [field05, setField05] = useState()
    const [field06, setField06] = useState()
    const [field07, setField07] = useState()
    const [field08, setField08] = useState()
    const [field09, setField09] = useState()
    const [check09, setCheck09] = useState(false)
    const [field10, setField10] = useState("")
    // const [field10, setField10] = useState()
    // const [field11, setField11] = useState()
    // const [field12, setField12] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    const openADO = (() => {
        // console.log(field10)
        if (field10 !== "") {
            window.open(field10);
        }
    });

    const updateItem = () => {
        updateData.url = field01;
        updateData.CostCentre = field08;
        updateData.approved = field09;
        updateData.urlADO = field10;

        base.update("ManageData/tasks/upd/" + id, updateData).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        // //console.log("hangleChange", e.target.name)
        if (e.target.name === 'url') {
            setField01(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'taskName') {
            setField02(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'projectName') {
            setField03(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'organisationName') {
            setField04(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'isBillable') {
            setField05(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'billingRate') {
            setField06(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'status') {
            setField07(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'costCentre') {
            setField08(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'urlADO') {
            setField10(e.target.value);
            // console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'approved') {

            if (e.target.checked) {
                // console.log("Y");
                setField09("Y");
                setCheck09(true)
            }
            else {
                // console.log("N");
                setField09("N");
                setCheck09(false)
            }
            // setField09(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    useEffect(() => {
        const getData = () => {
            // if (editExisting) {
            // setDisableUPN(dU => true)
            const getFormData = async () => {
                await base.getAll("ManageData/tasks/" + id).then((res) => {
                    setField01(res.url);
                    setField02(res.taskName);
                    setField03(res.projectName);
                    setField04(res.organisationName);
                    setField05(res.isBillable);
                    setField06(res.billingRate);
                    setField07(res.status);
                    setField08(res.costCentre);
                    setField09(res.approved);
                    if (res.approved === "Y") {
                        setCheck09(true);
                    }
                    else {
                        setCheck09(false);
                    }
                    var link = (res.urlADO !== null ? res.urlADO : "")
                    setField10(link);
                    setLoading(false)
                })
            }

            getFormData();
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getData()
    }, [id])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Edit Task</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >URL</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="url" id="url" placeholder="" defaultValue={field01} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Task</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="taskName" id="taskName" placeholder="" defaultValue={field02} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Project</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="projectName" id="projectName" placeholder="" defaultValue={field03} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Client</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="organisationName" id="organisationName" placeholder="" defaultValue={field04} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">isBillable</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="isBillable" id="isBillable" placeholder="" defaultValue={field05} />
                                    </Col>
                                </Row>

                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">billingRate</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="billingRate" id="billingRate" placeholder="" defaultValue={field06} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Status</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="status" id="status" placeholder="" defaultValue={field07} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" onClick={openADO} style={{ cursor: "pointer" }}>ADO URL</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="urlADO" id="urlADO" placeholder="" value={field10} onChange={handleChange} />
                                    </Col>
                                </Row>

                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Cost Centre*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="costCentre" id="costCentre" className='input-text' value={field08} onChange={handleChange} >
                                            <option className='input-text' value="CC_KW">
                                                CC_KW
                                            </option>
                                            <option className='input-text' value="CC_LW">
                                                CC_LW
                                            </option>
                                            <option className='input-text' value="CC_MW">
                                                CC_MW
                                            </option>
                                            <option className='input-text' value="CC_WEB">
                                                CC_WEB
                                            </option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Approved*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="checkbox" checked={check09} name="approved" id="approved" className='input-check' onChange={handleChange} >
                                            {/* <option className='input-text' value="Y">
                                                Yes
                                            </option>
                                            <option className='input-text' value="N">
                                                No
                                            </option> */}
                                        </Input>
                                        {/* <select name="timeslips" id="timeslips">
                                            <option value="null"></option>
                                            <option value="billed_grouped_by_single_timeslip">billed grouped by single timeslip</option>
                                            <option value="billed_grouped_by_timeslip">billed grouped by timeslip</option>
                                            <option value="billed_grouped_by_timeslip_task">billed grouped by timeslip task</option>
                                            <option value="billed_grouped_by_timeslip_date">billed grouped by timeslip date</option>
                                        </select> */}
                                    </Col>
                                </Row>

                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button>
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default TasksModal
