import { useState, useLayoutEffect } from 'react';
import '../../assets/css/Navbar.css';
import { Navbar, Collapse, Nav, NavbarToggler, UncontrolledDropdown, DropdownMenu, DropdownToggle, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { ReactComponent as Down16 } from '../../assets/icons/down-large-16.svg';
import { ReactComponent as Up16 } from '../../assets/icons/up-large-16.svg';
import { AiOutlineCluster, AiOutlineCode, AiOutlineInfoCircle, AiOutlineOrderedList, AiOutlineProject, AiOutlineTeam } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Remove48 } from '../../assets/icons/remove48.svg';
import { ReactComponent as Hamburger48 } from '../../assets/icons/hamburger-48.svg';

const Navibar = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
    const [manageDataOpen, setManageDataOpen] = useState(false);
    const [reportsOpen, setReportsOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    useLayoutEffect(() => {
        setIsOpen(false);
    }, [location]);

    const toggle = () => setIsOpen(!isOpen);

    const handleLogin = async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            try {
                const tokenResponse = await instance.handleRedirectPromise()
                if (!tokenResponse) {
                    const accounts = instance.getAllAccounts();
                    if (accounts.length === 0) {
                        instance.loginRedirect();
                    }
                } else {
                    alert("Error during handleRedirectPromise");
                }
            }
            catch (error) {
                console.error(error);
                alert(error);
            }
        };
    };

    const handleDropdown = (event) => {
        const elementID = event.target.id.split(":")[0];
        const setDropdown = (dropdown) => {
            setDropdownOpen(dropdown === 'work');
            setCompanyOpen(dropdown === 'company');
            setManageDataOpen(dropdown === 'managedata');
            setReportsOpen(dropdown === 'reports');
            setProfileOpen(dropdown === 'profile');
        };

        if (window.innerWidth >= 992) {
            if (event.type === 'mouseenter') {
                setDropdown(elementID);
            } else if (event.type === 'mouseleave') {
                setDropdown(null);
            }
        } else if (event.type === 'click') {
            setDropdownOpen(elementID === 'work' && !dropdownOpen);
            setCompanyOpen(elementID === 'company' && !companyOpen);
            setManageDataOpen(elementID === 'managedata' && !manageDataOpen);
            setReportsOpen(elementID === 'reports' && !reportsOpen);
            setProfileOpen(elementID === 'profile' && !profileOpen);
        }
    };

    const handleLink = () => {
        setDropdownOpen(false);
        setCompanyOpen(false);
        setManageDataOpen(false);
        setReportsOpen(false);
        setProfileOpen(false);
    };

    return (
        <Navbar className="bg-Nav" light expand="lg">
            <div className="mt-1 mb-1 ms-2">
                <Link to='/' className="nav-brand">
                    <span>Mobyte</span>
                </Link>
            </div>
            <NavbarToggler onClick={toggle} className="p-0 border-0">
                {isOpen ? <Remove48 /> : <Hamburger48 />}
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="ms-auto">
                    <UncontrolledDropdown
                        id="work:uncontrolledDrop"
                        inNavbar
                        nav
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onClick={handleDropdown}
                        isOpen={dropdownOpen}
                        style={{ cursor: "default" }}
                    >
                        <DropdownToggle nav className="me-5 ms-2" id="work:dropToggle">
                            <span id="work:span" className="nav-text">
                                Our Work {dropdownOpen ? <Up16 id="work:img1" /> : <Down16 id="work:img2" />}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-left" id="work:dropMenu">
                            <Row id="work:row">
                                <Col xs="12" id="work:col">
                                    <Nav vertical id="work:nav">
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/services" onClick={handleLink}>
                                            <AiOutlineCode size={20} id="work:icon1" />
                                            &nbsp;&nbsp;<span id="work:span1">Services</span>
                                        </Col>
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/process" onClick={handleLink}>
                                            <AiOutlineOrderedList size={20} id="work:icon2" />
                                            &nbsp;&nbsp;<span id="work:span2">Process</span>
                                        </Col>
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/projects" onClick={handleLink}>
                                            <AiOutlineProject size={20} id="work:icon3" />
                                            &nbsp;&nbsp;<span id="work:span3">Projects</span>
                                        </Col>
                                    </Nav>
                                </Col>
                            </Row>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                        inNavbar
                        id="company:uncontrolledDrop"
                        nav
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onClick={handleDropdown}
                        isOpen={companyOpen}
                        style={{ cursor: "default" }}
                    >
                        <DropdownToggle nav className="me-5 ms-2" id="company:dropToggle">
                            <span id="company:span" className="nav-text">
                                Company {companyOpen ? <Up16 id="company:img1" /> : <Down16 id="company:img2" />}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-left" id="company:dropMenu">
                            <Row id="company:row">
                                <Col xs="12" id="company:col">
                                    <Nav vertical id="company:nav">
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/about" onClick={handleLink}>
                                            <AiOutlineInfoCircle size={20} />
                                            &nbsp;&nbsp;<span>About Us</span>
                                        </Col>
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/team" onClick={handleLink}>
                                            <AiOutlineTeam size={20} />
                                            &nbsp;&nbsp;<span>Team</span>
                                        </Col>
                                        <Col className="p-2 nav-drp-link" tag={Link} to="/careers" onClick={handleLink}>
                                            <AiOutlineCluster size={20} />
                                            &nbsp;&nbsp;<span>Careers</span>
                                        </Col>
                                    </Nav>
                                </Col>
                            </Row>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <Col className="me-5 mt-2 mb-2 ms-2 pe-2">
                        <div className="nav-text" style={{ cursor: "pointer" }} onClick={handleLogin}>
                            <span>Login</span>
                        </div>
                    </Col>

                    <Link to="/contact">
                        <Button className="main-button btn-steel-blue ms-2" size="md">Get in touch</Button>
                    </Link>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Navibar;
