import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollableDiv = ({ children }) => {
    const location = useLocation();
    const scrollableRef = useRef(null);

    useLayoutEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({ top: 0 });
        }
    }, [location.pathname]);

    useEffect(() => {
        const scrollableElement = scrollableRef.current;

        const handleScroll = () => {
            const elements = document.querySelectorAll('.div-container, .block-left-scroll, .block-right-scroll');
            elements.forEach((element) => {
                const positionFromTop = element.getBoundingClientRect().top;
                const threshold = scrollableElement.clientHeight;

                if (positionFromTop < threshold) {
                    element.classList.add('visible');
                } else {
                    element.classList.remove('visible');
                }
            });
        };

        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="scroll-container" ref={scrollableRef}>
            {children}
        </div>
    );
};

export default ScrollableDiv;
