import React, { useEffect } from 'react';
import '../../../assets/css/Main.css'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/cables.jpg'

const Privacy = () => {

    useEffect(() => {
        document.title = 'Privacy policy | Mobyte';
    }, []);

    const subheading = (
        <span className="font-white">
            Privacy policy
        </span>
    )


    return (
        <>
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />

            <div className="mb-5 mt-4">
                <div className="container-large">
                    <div className="pb-4">
                        Last updated: 3rd July 2024
                    </div>
                    <ol>
                        <li className="pb-2">
                            <span className="terms-header">Introduction</span>
                            <p style={{ textAlign: "left" }} >
                                At Mobyte, we take the privacy of our users seriously and are committed to protecting their personal information. This privacy policy outlines the types of information we collect, how we use it, and the measures we take to keep it safe.                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Information collection</span>
                            <div style={{ textAlign: "left" }}>
                                We collect information from our users in the following ways:
                                <ul>
                                    <li>
                                        Contact forms: When users fill out contact forms on our website, they may be asked to provide personal information such as their name, email address, and message.
                                    </li>
                                    <li>
                                        Cookies: We use cookies to collect information about user behavior on our website, such as the pages they visit and how they interact with our content.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Information Use</span>
                            <div style={{ textAlign: "left" }}>
                                We use the information we collect for several purposes, including:
                                <ul>
                                    <li>
                                        Responding to user inquiries: When users fill out a contact form, we use their personal information to respond to their inquiries.
                                    </li>
                                    <li>
                                        Improving our website: We use information from cookies to analyze user behavior and improve our website and its content.
                                    </li>
                                    <li>
                                        Marketing purposes: We may use users' personal information to send them promotional emails or other marketing communications.
                                    </li>
                                    <li>
                                        Improving our website: We use information from cookies to analyze user behavior and improve our website and its content.
                                    </li>
                                </ul>

                            </div>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Information sharing and disclosure</span>
                            <div style={{ textAlign: "left" }}>
                                We do not share or sell personal information to third parties for any reason, except as required by law or with the user's explicit consent.
                            </div>
                        </li>

                        <li className="pb-2">

                            <span className="terms-header">Security</span>
                            <div style={{ textAlign: "left" }}>
                                We take several measures to ensure the security of our users' personal information, including:
                                <ul>
                                    <li>
                                        Secure servers: We store personal information on secure servers that are protected by firewalls and other security measures.
                                    </li>
                                    <li>
                                        Encryption: Personal information transmitted to and from our website is encrypted using secure socket layer (SSL) technology.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Changes to this privacy policy</span>
                            <div style={{ textAlign: "left" }}>
                                We reserve the right to modify this privacy policy at any time. If we make any significant changes, we will notify users by posting a notice on our website or by sending a notification to their email address.
                            </div>
                        </li>

                        <li className="pb-2">
                            <span className="terms-header">Contact us</span>
                            <p style={{ textAlign: "left" }}>
                                If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:info@mobyte.com">info@mobyte.com</a>
                            </p>
                        </li>
                    </ol>
                </div>
            </div>



        </>
    );
};

export default Privacy;