import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState } from 'react';
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'

const TasksModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState("")
    const [field02, setField02] = useState("")
    const [field03, setField03] = useState("")
    const [field04, setField04] = useState("")
    const [field05, setField05] = useState("")
    const [field06, setField06] = useState("")
    const [field07, setField07] = useState("")
    const [field08, setField08] = useState("")
    // const [field09, setField09] = useState()
    // const [field10, setField10] = useState()
    // const [field11, setField11] = useState()
    // const [field12, setField12] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};

    const toggleModal = () => {
        setShowModal(showModal => !showModal);
        closeModal()

    }

    const updateItem = () => {
        updateData.FirstName = field01;
        updateData.LastName = field02;
        updateData.url = field03;
        updateData.UPN = field04;
        updateData.Email = field05;
        updateData.UserLevel = field06;
        updateData.CostCentre = field08;

        base.update("ManageData/user/upd/" + id, updateData).then((res) => {
            setSaveItem(new Date().getTime());
            toggleModal()

        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        // //console.log("hangleChange", e.target.name)
        if (e.target.name === 'firstName') {
            setField01(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'lastName') {
            setField02(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'url') {
            setField03(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'upn') {
            setField04(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'email') {
            setField05(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'userLevel') {
            setField06(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'lastLogOn') {
            setField07(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'costCentre') {
            setField08(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    useEffect(() => {
        const getData = () => {
            // if (editExisting) {
            // setDisableUPN(dU => true)
            const getFormData = async () => {
                await base.getAll("ManageData/user/" + id).then((res) => {
                    setField01(res.firstName);
                    setField02(res.lastName !== null ? res.lastName : "");
                    setField03(res.url !== null ? res.url : "");
                    setField04(res.upn !== null ? res.upn : "");
                    setField05(res.email);
                    setField06(res.userLevel !== null ? res.userLevel : "");
                    // var llo = (res.lastLogOn !== null ? res.lastLogOn : 0)
                    setField07(res.lastLogOn !== null ? res.lastLogOn : "");
                    setField08(res.costCentre);
                    setLoading(false)
                })
            }

            getFormData();
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getData()
    }, [id])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Edit User</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >First Name*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="firstName" id="firstName" placeholder="" value={field01} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Last Name*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="lastName" id="lastName" placeholder="" value={field02} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">URL*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="url" id="url" placeholder="" value={field03} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">UPN*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="upn" id="upn" placeholder="" value={field04} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Email*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="email" id="email" placeholder="" value={field05} onChange={handleChange} />
                                    </Col>
                                </Row>

                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Cost Centre*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="costCentre" id="costCentre" placeholder="" value={field08} onChange={handleChange} />
                                    </Col>
                                </Row>

                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">UserLevel*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="userLevel" id="userLevel" className='input-text' value={field06} onChange={handleChange} >
                                            <option className='input-text' value="10">
                                                Director
                                            </option>
                                            <option className='input-text' value="20">
                                                Administrator
                                            </option>
                                            <option className='input-text' value="30">
                                                User
                                            </option>
                                        </Input>
                                        {/* <select name="timeslips" id="timeslips">
                                            <option value="null"></option>
                                            <option value="billed_grouped_by_single_timeslip">billed grouped by single timeslip</option>
                                            <option value="billed_grouped_by_timeslip">billed grouped by timeslip</option>
                                            <option value="billed_grouped_by_timeslip_task">billed grouped by timeslip task</option>
                                            <option value="billed_grouped_by_timeslip_date">billed grouped by timeslip date</option>
                                        </select> */}
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Last Login</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="lastLogOn" id="lastLogOn" placeholder="" value={field07} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button>
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default TasksModal
