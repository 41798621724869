import React, { useEffect } from 'react';
import '../../App.css';
import { Route, Routes, useLocation } from 'react-router-dom'
import Navbar from '../shared/navbar.js';
import Footer from '../shared/footer.js';
import Error404 from '../shared/error404.js';
import Services from '../mainsite/services/services.js';
import Process from '../mainsite/services/process.js';
import Projects from '../mainsite/services/projects.js';
import Technology from '../mainsite/services/technology.js';
import Team from '../mainsite/company/team.js';
import Careers from '../mainsite/company/careers.js';
import About from '../mainsite/company/about.js';
import Contact from '../mainsite/contact.js';
import Privacy from '../mainsite/terms/privacy.js';
import Cookie from '../mainsite/terms/cookie.js';
import Blog from '../mainsite/resources/blog.js';
import Bespoke from '../mainsite/resources/bespoke.js';
import Responsibility from '../mainsite/terms/responsibility.js';
import Terms from '../mainsite/terms/terms.js';
import Home from '../mainsite/homepage.js';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ServiceDetail from '../mainsite/servicesDetail.js';
import ProjectDetail from '../mainsite/projectDetail.js';
import { CookieProvider } from '../shared/cookieContext.js';
import CookieBanner from '../shared/cookieBanner.js';
import AuthenticatedRoutes from './AuthenticatedRoutes.js';
import ScrollableDiv from './scrollableDiv.js';
import { UserProvider } from '../context/userContext.js';


const PrivateRoute = () => {
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const location = useLocation();

    useEffect(() => {
        const setCanonicalUrl = () => {
            const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
            let link = document.querySelector("link[rel='canonical']");

            if (!link) {
                link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                document.head.appendChild(link);
            }
            link.setAttribute('href', canonicalUrl);
        };
        setCanonicalUrl();
    }, [location]);

    return (
        <>
            <UnauthenticatedTemplate>
                <Navbar loggedInName={name} />
                <ScrollableDiv >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/careers" element={<Careers />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/cookie" element={<Cookie />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/responsibility" element={<Responsibility />} />
                        <Route path="/bespoke/:blogId" element={<Bespoke />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/technology" element={<Technology />} />
                        <Route path="/process" element={<Process />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/projects/:projectId" element={<ProjectDetail />} />
                        <Route path="/services/:serviceId" element={<ServiceDetail />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                    <Footer />
                </ScrollableDiv>
                <CookieProvider>
                    <CookieBanner>
                    </CookieBanner >
                </CookieProvider>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <UserProvider>
                    <AuthenticatedRoutes />
                </UserProvider>
            </AuthenticatedTemplate>

        </>
    );
}

export default PrivateRoute;
