import React, { useEffect } from 'react'
import '../../../assets/css/Main.css'
import { FaUser } from 'react-icons/fa'
import { Row, Col } from 'reactstrap'
// import simg_KM from '..\assets\images\kmcnulty.jpeg'
import simg_KM from '../../../assets/images/kmcnulty.jpeg'
import simg_AS from '../../../assets/images/ashepherd.JPG'
import simg_LW from '../../../assets/images/profile.jpg'
import simg_LP from '../../../assets/images/lpurser_1.jpg'
import TextOnImage from '../textOnImage'
import image from '../../../assets/images/team1.jpg'

const Team = () => {
    useEffect(() => {
        document.title = 'Team | Our team of  IT experts | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Meet the talented and diverse team behind Mobyte. Our dedicated professionals bring expertise in software engineering, IT consulting, and development.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Team, Mobyte, talent, software engineering, IT consulting, development, expertise, collaboration, innovation, diversity, professionals, passionate, results-driven';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="font-white">
            Team
        </span>
    )

    return (
        <div className="">
            {/* <div className="bg-teal p-5 mb-5">
                <h1>Team</h1>
            </div> */}
            {/* <div className="bg-teal ps-5 pe-5 pt-3 pb-3 mb-5">
                <div className="main-heading">Team</div>
                <p className="main-subheading">People are the most important aspect of any business, and our company is committed to recruiting honest, conscientious and reliable employees.</p>
            </div> */}
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Team working around desk"} />



            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={`content-heading font-darkteal`}>
                        Our team of <span className="header-brush">IT experts</span>&nbsp;
                    </h2>
                </div>
                <Row className="block-left-scroll visible">
                    <div className="content-heading font-darkteal heading-block ">
                        Senior Management
                    </div>
                </Row>
                <Row className="text-center pb-5 block-left-scroll visible">
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        <div>
                            <div className="circular--portrait"><div><FaUser size={140} color="#cecece" /></div>
                            </div>
                        </div>
                        <br /><h3>Mike Whale</h3>
                        <div className="text-muted text-wide">Founder & CEO</div>
                    </Col>
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        {/* <div><FaUser size={65} color="#0275d8" /></div> */}
                        <div>
                            <div className="circular--portrait"> <img src={simg_LW} alt="Alex Shepherd" /> </div>
                        </div>
                        <br /><h3>Lawrence Whale</h3>
                        <div className="text-muted text-wide">Managing Director</div>
                    </Col>
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        <div>
                            <div className="circular--portrait"><div><FaUser size={140} color="#cecece" /></div>
                            </div>
                        </div>
                        <br /><h3>Anne Whale</h3>
                        <div className="text-muted text-wide">Finance Director</div>
                    </Col>
                </Row>
                <Row className="block-left-scroll">
                    <div className="content-heading font-darkteal heading-block">
                        Development Team
                    </div>
                </Row>
                <Row className="text-center pb-5 block-left-scroll">
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        {/* <div><FaUser size={65} color="#0275d8" /></div> */}
                        <div>
                            <div className="circular--portrait"> <img src={simg_AS} alt="Alex Shepherd" /> </div>
                        </div>
                        <br /><h3>Alex Shepherd</h3>
                        <div className="text-muted text-wide">Full Stack Developer</div>
                    </Col>
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        <div>
                            <div className="circular--portrait"><div><img src={simg_LP} alt="Laura Purser" /></div>
                            </div>
                        </div>
                        <br /><h3>Laura Purser</h3>
                        <div className="text-muted text-wide">Junior Developer</div>
                    </Col>
                </Row>
                <Row className="block-left-scroll">
                    <div className="content-heading font-darkteal heading-block">
                        Administration Team
                    </div>
                </Row>
                <Row className="text-center pb-5 block-left-scroll">
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        <div>
                            <div className="circular--portrait"><div><FaUser size={140} color="#cecece" /></div>
                            </div>
                        </div>
                        <br /><h3>Jodie Whale</h3>
                        <div className="text-muted text-wide">HR Manager</div>
                    </Col>
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        {/* <div><FaUser size={65} color="#0275d8" /></div> */}

                        <div>
                            <div className="circular--portrait"> <img src={simg_KM} alt="Katherine McNulty" /> </div>
                        </div>
                        <br /><h3>Katherine McNulty</h3>
                        <div className="text-muted text-wide">Marketing Manager</div>
                    </Col>
                    <Col xs="12" lg="3" md="4" className="pb-3">
                        <div>
                            <div className="circular--portrait"><div><FaUser size={140} color="#cecece" /></div>
                            </div>
                        </div>
                        <br /><h3>Kathy Knowler</h3>
                        <div className="text-muted text-wide">Accounts Administrator</div>
                    </Col>
                </Row>
                {/* 
            <div className="container-mainsite mt-4">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-heading">Senior Management</h2>
                    </div>
                </div>
                <br />
                <div className="row" style="align-content:center;text-align:center">
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Mike Whale</h5>
                        <h6 className="text-muted">Founder & CEO</h6>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Lawrence Whale</h5>
                        <h6 className="text-muted">Managing Director</h6>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Anne Whale</h5>
                        <h6 className="text-muted">Finance Director</h6>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-heading">Operations Team</h2>
                    </div>
                </div>
                <br />
                <div className="row" style="align-content:center;text-align:center">
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Alex Shepherd</h5>
                        <h6 className="text-muted">Senior Developer</h6>
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Jodie Cowler</h5>
                        <h6 className="text-muted">HR Manager</h6>
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Katherine McNulty</h5>
                        <h6 className="text-muted">Marketing Manager</h6>
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Kathy Knowler</h5>
                        <h6 className="text-muted">Accounts Administrator</h6>
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div><span className="fa fa-user fa-4x text-light-teal"></span></div>
                        <br /><h5>Alan Whale</h5>
                        <h6 className="text-muted">Product Tester</h6>
                        <br />
                    </div>
                </div>
            </div> */}
            </div>
        </div >
    )
}

export default Team