import React, { createContext, useState, useContext, useMemo, useEffect } from "react";
import base from '../../services/BaseService.js';

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState([]);

    useEffect(() => {
        const getUser = async () => {
            try {
                const userRes = await base.getAll("Report/user");
                setUser(userRes);
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };
        getUser();
    }, []);

    const value = useMemo(() => ({ user }), [user]);
    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};