import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineWallet } from 'react-icons/ai';
import refresh from '../../../assets/icons/refresh24.svg';
import download from '../../../assets/icons/download24.svg';


// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import Spinner from '../../shared/loading'

const VAT = ({ closeModal, showModalForm, displayModal, invCreate, loading4, setLoading5 }) => {

    const gridRef = useRef();
    const [budgetData, setBudgetData] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    // const [showModal, setShowModal] = useState(showModalForm)
    const [expLoading, setExpLoading] = useState(true);

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);

    // const toggleModal = () => {
    //     closeModal()
    //     setShowModal(showModal => !showModal);
    // }

    // const isFirstColumn = useCallback((params) => {
    //     if (invCreate) {
    //         var displayedColumns = params.columnApi.getAllDisplayedColumns();
    //         var thisIsFirstColumn = displayedColumns[0] === params.column;
    //         return thisIsFirstColumn;
    //     }

    // }, [invCreate]);



    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });



        setBudgetColDef([
            {
                headerName: "Date",
                field: "dated_on",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Name",
                field: "name",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Description",
                field: "description",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Detail",
                field: "detail",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Cost Centre",
                field: "cost_centre",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 400
            },
            {
                headerName: "Value",
                field: "value",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200,
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',

            },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, [currencyFormatter]);

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("Report/costcentre").then((res) => {
                setBudgetData(res);
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])
    }, [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const refreshTransactions = () => {
        const getTransactions = async () => {
            await base.getAll("FreeAgent/refreshTransactions").then((res) => {
                base.getAll("Report/costcentre").then((res) => {
                    setBudgetData(res);
                    setExpLoading(false);
                });
                // setBudgetData(res);
            });
        }
        setExpLoading(true);
        getTransactions()
    }



    // const onSelectionChanged = useCallback((event) => {
    //     var rowCount = event.api.getSelectedNodes().length;
    //     window.alert('selection changed, ' + rowCount + ' rows selected' + event.node.isSelected());
    // }, []);

    return (
        <>
            {/* {!displayModal ? */}
            <>

                <CardHeader className="pt-3 pb-3">
                    <Row>
                        <Col xs="12" sm="9" className="page-header">
                            <AiOutlineWallet size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                            <span className="section-heading">Cost Centre Allocation</span>
                        </Col>
                        {/* <span > */}

                        <Col xs="12" sm="3" className="text-end pe-4">
                            <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => onBtnExport()}>
                                <img src={download} alt="download" />
                            </Button>
                            <Button size="sm" color="light border-dark" className="btn-text" style={{ height: "38px" }} onClick={() => refreshTransactions()}>
                                <img src={refresh} alt="refresh" />
                            </Button>
                        </Col>
                        {/* </span> */}
                    </Row>
                </CardHeader>
                <div className="container-fluid">

                    <Row>
                        <Col xs="12">

                            {expLoading ?
                                <CardBody style={{ height: "750px" }}>
                                    <div className="row align-items-center">
                                        <div className="text-center" >
                                            <Spinner />
                                        </div>
                                    </div>
                                </CardBody>
                                :
                                <>
                                    {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                    <CardBody style={{ height: "680px" }}>
                                        <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={budgetData}
                                                columnDefs={budgetColDef}
                                                overlayNoRowsTemplate="No Records Found"
                                                defaultColDef={gridDefaultColDef}
                                                pagination={true}
                                                // onGridReady={onGridReady}
                                                rowSelection={'single'}
                                                // rowSelection={'single'}
                                                paginationPageSize={20}
                                            // gridOptions={gridOptions}
                                            // onRowDoubleClicked={onSelectionChanged}
                                            // onGridReady={onGridReady}
                                            // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                            // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                            // onRowDoubleClicked=(()=> editRow())
                                            >
                                            </AgGridReact>
                                        </div>
                                    </CardBody>
                                </>

                            }

                        </Col>

                    </Row>
                </div>
                {/* {showModal && <BudgetModal closeModal={closeModal} showModalForm={true} />} */}
                {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

            </>

        </>

    )
}

export default VAT
