import React, { useContext } from 'react';
import { CookieContext } from './cookieContext.js';
import { Row, Col } from 'reactstrap'
import '../../assets/css/cookie.css'

const CookieBanner = () => {
    const { cookiesAccepted, acceptCookies, rejectCookies } = useContext(CookieContext);

    if (cookiesAccepted !== undefined) {
        return null;
    }

    return (
        <>
            <div className="cookie-banner">
                <Row>
                    <Col xs="12">
                        We use cookies to improve your experience on our website, analyze traffic, and personalize content. By continuing to use this site, you consent to our use of cookies. <a href="https://www.mobyte.com/cookie" target="_blank" rel="noreferrer">Cookie policy</a>.
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={acceptCookies}>
                            Accept
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={rejectCookies}>
                            Decline
                        </span>
                    </Col>
                </Row>
            </div >
        </>
    );
};

export default CookieBanner;
