import React, { useEffect } from 'react';
import '../../../assets/css/Main.css'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/cables.jpg'

const Privacy = () => {

    useEffect(() => {
        document.title = 'Cookie policy | Mobyte';
    }, []);

    const subheading = (
        <span className="font-white">
            Cookie policy
        </span>
    )


    return (
        <>
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />
            <div className="mb-5 mt-4">
                <div className="container-large">
                    <div className="pb-4">
                        Last updated: 3rd July 2024
                    </div>
                    <ol>
                        <li className="pb-2">
                            <span className="terms-header">Introduction</span>
                            <p style={{ textAlign: "left" }} >
                                This policy explains how we use cookies and similar technologies on our website. By using our website, you consent to the use of cookies as described in this policy.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">What are cookies?</span>
                            <p style={{ textAlign: "left" }} >
                                Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the site.                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header"> Types of cookies we use</span>
                            <p style={{ textAlign: "left" }}>
                                We use the following types of cookies on our website:
                            </p>
                            <ul>
                                <li>
                                    <strong>Essential cookies:</strong> These cookies are necessary for the website to function properly. They enable you to navigate the site and use its features. Without these cookies, services you have asked for (such as navigating between pages) cannot be provided.
                                </li>
                                <li>
                                    <strong>Analytics cookies:</strong> These cookies allow us to analyze how visitors use our website, so we can measure and improve its performance. For example, we use Google Analytics cookies to understand how users interact with our site.
                                </li>
                            </ul>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">How we use cookies</span>
                            <p style={{ textAlign: "left" }}>
                                We use cookies for the following purposes:
                            </p>
                            <ul>
                                <li>
                                    To provide essential functionality and allow you to navigate our website.
                                </li>
                                <li>
                                    To enhance and personalize your experience on our website.
                                </li>
                                <li>
                                    To analyze usage patterns on our website and gather anonymous statistical data.
                                </li>
                                <li>
                                    To deliver targeted advertising based on your browsing activities.
                                </li>
                            </ul>
                        </li>

                        <li className="pb-2">
                            <span className="terms-header">Managing cookies</span>
                            <p style={{ textAlign: "left" }}>
                                Most web browsers allow you to control cookies through their settings preferences. However, please note that disabling essential cookies may impact the functionality of our website and prevent you from accessing certain features.                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Your consent</span>
                            <p style={{ textAlign: "left" }}>
                                By using our website, you consent to the use of cookies in accordance with this cookie policy.
                            </p>
                            <ul>
                                <li>
                                    <strong>Functional cookies:</strong> functional cookies are a requirement of the site. If you do not agree to these, please refrain from using our website.
                                </li>
                                <li>
                                    <strong>Analytics cookies:</strong> if you do not agree to these, please click decline in the cookie banner presented at the bottom of this page.
                                </li>
                            </ul>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Changes to this policy</span>
                            <p style={{ textAlign: "left" }}>
                                We may update this Cookie Policy from time to time to reflect changes in our practices and legal obligations. We encourage you to review this policy periodically for any updates.                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Contact us</span>
                            <p style={{ textAlign: "left" }}>
                                If you have any questions regarding these Cookie policies, please contact us at <a href="mailto:info@mobyte.com">info@mobyte.com</a>
                            </p>
                        </li>
                    </ol>


                </div>
            </div>

        </>
    );
};

export default Privacy;