import axios from "axios";
import { graphConfig, graphLoginRequest, loginRequest, msalConfig } from "../authconfig";
import { PublicClientApplication } from '@azure/msal-browser';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

const msalInstance = new PublicClientApplication(msalConfig);

const getToken = async (request) => {
    try {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No accounts found in MSAL instance.");
        const response = await msalInstance.acquireTokenSilent({ ...request, account });
        return response.accessToken;
    } catch (error) {
        console.error("Error acquiring token:", error.message);
        throw error;
    }
};

const sendRequest = async (method, url, data, tokenRequest, additionalConfig = {}) => {
    try {
        const token = await getToken(tokenRequest);
        const config = {
            method,
            url,
            data,
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: false,
            ...additionalConfig,
        };
        const response = await axiosInstance(config);
        return response.data || response;
    } catch (error) {
        console.error(`Error making ${method.toUpperCase()} request to ${url}:`, error.message);
        throw error;
    }
};

const base = {
    getAll: (url) => sendRequest("get", url, null, loginRequest),

    get: (url, id) => sendRequest("get", `${url}/${id}`, null, loginRequest),

    post: (url, data) => sendRequest("post", url, data, loginRequest),

    getPhoto: () => sendRequest(
        "get",
        graphConfig.graphPhotoEndpoint,
        null,
        graphLoginRequest,
        { responseType: "arraybuffer" }
    ),

    qry: (url, id) => sendRequest("get", `${url}/${id}`, null, loginRequest),

    add: (url, data) => sendRequest("post", url, data, loginRequest),

    update: (url, data) => sendRequest("put", url, data, loginRequest), // Use PUT for updates

    delete: (url, id) => sendRequest("delete", `${url}/${id}`, null, loginRequest),
};

export default base;
