// CookieContext.js
import React, { createContext, useState } from 'react';
import Cookies from "universal-cookie";

export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
    var cookies = new Cookies();
    const accepted = cookies.get("cookiesAccepted");
    const [cookiesAccepted, setCookiesAccepted] = useState(accepted);

    const acceptCookies = () => {
        setCookiesAccepted(true);
        cookies.set("cookiesAccepted", "accept");
    };

    const rejectCookies = () => {
        setCookiesAccepted(false);
        cookies.set("cookiesAccepted", "decline");
    };

    const contextValue = {
        cookiesAccepted,
        acceptCookies,
        rejectCookies
    };

    return (
        <CookieContext.Provider value={contextValue}>
            {children}
        </CookieContext.Provider>
    );
};
