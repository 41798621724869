import React from 'react'
import { useEffect, useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter, CardBody } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'
import BlogDetailModal from "./blogDetailModal"

const TasksModal = ({ id, setID, closeModal, showModalForm, setSaveItem, setCreate, create }) => {
    const gridRef = useRef();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [blogColDef, setBlogColDef] = useState([]);
    const [detailData, setDetailData] = useState([])
    const [singleDetailData, setSingleDetailData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const [showDetailModal, setShowDetailModal] = useState(false)
    const [saveDetail, setSaveDetail] = useState(true)
    const [createDetail, setCreateDetail] = useState(false)
    const [heading, setHeading] = useState("")
    const [subheading, setSubheading] = useState("")
    const [author, setAuthor] = useState("")
    const [display, setDisplay] = useState("")
    const [datePosted, setDatePosted] = useState("")
    const [readTime, setReadTime] = useState("")
    const [positionInArray, setPositionInArray] = useState("")
    const [moveUpDisabled, setMoveUpDisabled] = useState(true)
    const [moveDownDisabled, setMoveDownDisabled] = useState(true)
    const [invalid, setInvalid] = useState([])


    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    useEffect(() => {
        if (create === false) {
            const getBlogData = () => {
                const getFormData = async () => {
                    await base.getAll("managedata/blog/qry/" + id).then((res) => {
                        setHeading(res[0].heading);
                        setAuthor(res[0].author);
                        setDisplay(res[0].display);
                        setDatePosted(res[0].post_date)
                        setReadTime(res[0].read_time)
                        setSubheading(res[0].subheading)
                        setLoading(false)
                    })
                }
                getFormData();

                const getDetailData = async () => {
                    await base.getAll("managedata/blog/blogdetail/qry/" + id).then((res) => {
                        setDetailData(res)
                    })
                }
                getDetailData();
            }
            getBlogData()
        }
    }, [id, saveDetail, create])

    const deleteItem = () => {
        const blogToDelete =
        {
            "heading": heading,
            "subheading": subheading,
            "author": author,
            "display": display,
            "post_date": datePosted,
            "read_time": readTime,
        }
        base.update("managedata/blog/del/" + id, blogToDelete).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
    }

    const updateItem = () => {
        const fail = []

        if (heading === "") {
            fail.push("heading")
        }
        if (subheading === "") {
            fail.push("subheading")
        }
        if (author === "") {
            fail.push("author")
        }
        if (datePosted === "") {
            fail.push("date")
        }
        if (readTime === "") {
            fail.push("readTime")
        }

        setInvalid(fail)

        if (fail.length === 0) {
            if (create === false) {
                const updatedBlog =
                {
                    "heading": heading,
                    "subheading": subheading,
                    "author": author,
                    "display": display,
                    "post_date": datePosted,
                    "read_time": readTime,
                    "id": id
                }
                base.update("managedata/blog/upd/" + id, updatedBlog).then((res) => {
                    toggleModal()
                    setSaveItem(new Date().getTime());
                })
            } else {
                const newBlog =
                    [{
                        "heading": heading,
                        "subheading": subheading,
                        "author": author,
                        "display": display,
                        "post_date": datePosted,
                        "read_time": readTime,
                    }]
                base.add("managedata/blog/add", newBlog).then((res) => {
                    setSaveItem(new Date().getTime());
                    setCreate(false)
                    setID(res.data[0].id)
                })
            }
        }
    }

    const handleChange = (e) => {
        setInvalid(prevInvalid =>
            prevInvalid.filter(element => element !== e.target.name)
        );

        switch (e.target.name) {
            case "heading":
                setHeading(e.target.value);
                break;
            case "subheading":
                setSubheading(e.target.value);
                break;
            case "author":
                setAuthor(e.target.value);
                break;
            case "display":
                setDisplay(e.target.checked);
                break;
            case "date":
                setDatePosted(e.target.value);
                break;
            case "readTime":
                setReadTime(e.target.value);
                break;
            default:
                break;
        }
    }

    const openDetailModal = (type) => {
        if (type === "create") {
            setSingleDetailData([])
            setCreateDetail(true)
        }
        setShowDetailModal(true)
    }

    const closeDetailModal = () => {
        setShowDetailModal(showModal => false)
    }

    const onDetailSelect = () => {
        let detail = gridRef.current.api.getSelectedRows();
        let sequenceNumber = detail[0].sequence

        setPositionInArray(sequenceNumber)

        if (sequenceNumber === 0) {
            setMoveUpDisabled(true)
        } else {
            setMoveUpDisabled(false)
        }

        if (sequenceNumber === detailData.length - 1) {
            setMoveDownDisabled(true)
        } else {
            setMoveDownDisabled(false)
        }
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setSingleDetailData(selectedRows)
            openDetailModal();
            setCreateDetail(false)
        }
    };

    const moveDetail = (position, newPosition) => {
        const items = detailData
        const movedItem = items.splice(position, 1);

        items.splice(newPosition, 0, movedItem[0])

        base.update("managedata/blogdetail/resequence", items).then((res) => {
            setSaveDetail(!saveDetail)
            setMoveUpDisabled(true)
            setMoveDownDisabled(true)
        })
    }

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBlogColDef([
            {
                headerName: "Heading",
                field: "heading",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Text",
                field: "plainText",
                suppressMovable: true,
                maxWidth: 900
            },
        ]);
    }, []);

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    return (
        <>
            <Modal isOpen={showModal} size="xl" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Blog details</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Heading</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="heading" id="heading" placeholder="" onChange={handleChange} value={heading} invalid={invalid.includes("heading")} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Subheading</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type='textarea' id="subheading" name="subheading" style={{ minHeight: "130px" }} className="form-control input-text-locked" rows="2" onChange={handleChange} value={subheading} invalid={invalid.includes("subheading")} ></Input>
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Author</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="author" id="author" placeholder="" onChange={handleChange} value={author} invalid={invalid.includes("author")} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Date posted</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="date" id="date" placeholder="" onChange={handleChange} value={datePosted} invalid={invalid.includes("date")} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Read time</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text-locked" name="readTime" id="readTime" placeholder="" onChange={handleChange} value={readTime} invalid={invalid.includes("readTime")} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Display</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="checkbox" checked={display} className="" name="display" id="display" placeholder="" onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            {id ?
                                <Row className="">
                                    <Col lg="6">
                                        <Label className="label-text mb-0" >Body text</Label>
                                    </Col>
                                    <Col lg="6" className="text-end">
                                        <Button size="sm" color="light border-dark" disabled={moveUpDisabled} className="ps-3 pe-3 me-1" style={{ height: "30px", fontSize: "12px" }} onClick={() => moveDetail(positionInArray, positionInArray - 1)} >
                                            Move up
                                        </Button>
                                        <Button size="sm" color="light border-dark" disabled={moveDownDisabled} className="ps-3 pe-3 me-1" style={{ height: "30px", fontSize: "12px" }} onClick={() => moveDetail(positionInArray, positionInArray + 1)} >
                                            Move down
                                        </Button>
                                        <Button size="sm" color="light border-dark" className="ps-3 pe-3 me-1" style={{ height: "30px", fontSize: "12px" }} onClick={() => openDetailModal("create")} >
                                            Add detail
                                        </Button>
                                    </Col>
                                    <CardBody style={{ height: "350px" }}>
                                        <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={detailData}
                                                columnDefs={blogColDef}
                                                overlayNoRowsTemplate="No Records Found"
                                                defaultColDef={gridDefaultColDef}
                                                pagination={true}
                                                rowSelection={'single'}
                                                paginationPageSize={20}
                                                onRowDoubleClicked={onSelectionChanged}
                                                onRowClicked={onDetailSelect}
                                            >
                                            </AgGridReact>
                                        </div>
                                    </CardBody>
                                </Row>
                                :
                                ""
                            }

                        </ModalBody>
                        <ModalFooter>
                            {create === false ? <Button color="success" size="sm" className="btn-text b-width" onClick={() => deleteItem()}>Delete</Button> : ""}
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()} >Save</Button>
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Close</Button>
                        </ModalFooter>
                    </>
                }
            </Modal >

            {showDetailModal && <BlogDetailModal
                saveDetail={saveDetail} createDetail={createDetail} setSaveDetail={setSaveDetail} singleDetailData={singleDetailData} detailData={detailData} setMoveUpDisabled={setMoveUpDisabled} setMoveDownDisabled={setMoveDownDisabled} closeModal={closeDetailModal} showModalForm={true} setSaveItem={setSaveItem} id={id} />
            }
        </>
    )
}

export default TasksModal
