import Error404 from './error404.js';
import Contacts from '../loggedIn/manageData/contacts.js';
import ToInvoice from '../loggedIn/reports/toinvoice.js';
import VAT from '../loggedIn/reports/vat.js';
import Transactions from '../loggedIn/reports/transactions.js';
import Categories from '../loggedIn/reports/categories.js';
import CostCentre from '../loggedIn/reports/costCentre.js';
import ContactForm from '../loggedIn/reports/contactForm.js';
import Tasks from '../loggedIn/manageData/tasks.js';
import Users from '../loggedIn/manageData/users.js';
import CCRecon from '../loggedIn/manageData/ccRecon.js';
import ClientProjects from '../loggedIn/manageData/clientProjects.js';
import CodeGenerator from '../loggedIn/manageData/codeGenerator.js';
import Dashboard from '../loggedIn/dashboard.js';
import ProjectManagement from '../loggedIn/manageData/projectManagement.js';
import BlogManagement from '../loggedIn/manageData/blogManagement.js';

const routeConfig = [
    { path: "/", element: <Dashboard />, access: ["10", "20", "30"], category: "general", name: "Dashboard", icon: <></> },
    { path: "/categories", element: <Categories />, access: ["10"], category: "reports", name: "Categories", icon: <></> },
    { path: "/costCentre", element: <CostCentre />, access: ["10"], category: "reports", name: "Cost Centre", icon: <></> },
    { path: "/contactform", element: <ContactForm />, access: ["10", "20", "30"], category: "reports", name: "Contact Form", icon: <></> },
    { path: "/toinvoice", element: <ToInvoice displayModal={false} />, access: ["10"], category: "reports", name: "To Invoice", icon: <></> },
    { path: "/transactions", element: <Transactions />, access: ["10"], category: "reports", name: "Transactions", icon: <></> },
    { path: "/vat", element: <VAT />, access: ["10"], category: "reports", name: "VAT", icon: <></> },
    { path: "/blogmanagement", element: <BlogManagement />, access: ["10", "20", "30", "40", "50"], category: "manageData", name: "Blog Management", icon: <></> },
    { path: "/clientprojects", element: <ClientProjects />, access: ["10"], category: "manageData", name: "Client Projects", icon: <></> },
    { path: "/ccrecon", element: <CCRecon />, access: ["10"], category: "manageData", name: "Cost Centre Recon", icon: <></> },
    { path: "/codegenerator", element: <CodeGenerator />, access: ["10", "20", "30", "40", "50"], category: "manageData", name: "Code Generator", icon: <></> },
    { path: "/projectmanagement", element: <ProjectManagement />, access: ["10", "20", "30", "40", "50"], category: "manageData", name: "Project Management", icon: <></> },
    { path: "/projects", element: <Contacts />, access: ["10"], category: "manageData", name: "Projects", icon: <></> },
    { path: "/tasks", element: <Tasks />, access: ["10"], category: "manageData", name: "Tasks", icon: <></> },
    { path: "/users", element: <Users />, access: ["10"], category: "manageData", name: "Users", icon: <></> },
    { path: "*", element: <Error404 />, access: ["10", "20", "30", "40", "50"], category: "exclude", name: "Error 404", icon: <></> },
];

export default routeConfig;