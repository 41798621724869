import React, { useEffect } from 'react'
import '../../assets/css/Main.css'
import Error404 from '../../assets/images/error404.svg'
import image from '../../assets/images/cables.jpg'
import TextOnImage from '../mainsite/textOnImage'
const Error404Component = () => {

    useEffect(() => {
        document.title = '404 - Page Not Found';
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'noindex';
        document.head.appendChild(metaRobots);

        return () => {
            document.head.removeChild(metaRobots);
        };
    }, []);

    const subheading = (
        <span className="font-white">
            404 Not Found
        </span>
    )

    return (
        <div className="">
            <TextOnImage imageUrl={image} heading={"404 Not Found"} subheading={subheading} fromHome={false} altText={"Connected cables"} />

            <div className="container-large pt-4 pb-5">
                <div className="text-center pt-3 pb-3">
                    <img className="img404" src={Error404} alt="404 error" style={{ height: "400px" }} />
                </div>

            </div>
        </div >
    )
}

export default Error404Component