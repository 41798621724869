import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState } from 'react';
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'

const TasksModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState("")
    const [field02, setField02] = useState("")
    const [field03, setField03] = useState("")
    const [field04, setField04] = useState("")
    const [field05, setField05] = useState("")
    const [field06, setField06] = useState("")
    // const [field07, setField07] = useState()
    const [field08, setField08] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    // const [field09, setField09] = useState()
    // const [field10, setField10] = useState()
    // const [field11, setField11] = useState()
    // const [field12, setField12] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};
    const periodValue = new Date().getFullYear() + '.' + ("0" + (new Date().getMonth() + 1)).slice(-2);

    const toggleModal = () => {
        setShowModal(showModal => !showModal);
        closeModal()
    }

    const updateItem = () => {

        updateData.fromCC = field01;
        updateData.toCC = field02;
        updateData.reason = field03;
        updateData.period = field04;
        updateData.value = field05;
        updateData.reason1 = field06;
        updateData.reason2 = field08;

        if (id === "new") {
            base.add("ManageData/ccrecon/add", updateData).then((res) => {
                setSaveItem(new Date().getTime());
                toggleModal()
            })
        } else {

            base.update("ManageData/ccrecon/upd/" + id, updateData).then((res) => {
                setSaveItem(new Date().getTime());
                toggleModal()
            })
        }
        // alert("Save!")
    }

    const deleteItem = () => {
        updateData.fromCC = field01;
        updateData.toCC = field02;
        updateData.reason = field03;
        updateData.period = field04;
        updateData.value = field05;
        updateData.reason1 = field06;
        updateData.reason2 = field08;

        base.update("ManageData/ccrecon/del/" + id, updateData).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        // //console.log("hangleChange", e.target.name)
        if (e.target.name === 'fromCC') {
            setField01(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'toCC') {
            setField02(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'reason') {
            setField03(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'period') {
            setField04(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'value') {
            setField05(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'reason1') {
            setField06(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'reason2') {
            setField08(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    useEffect(() => {
        const getData = () => {
            // if (editExisting) {
            // console.log(id)

            if (id === "new") {
                setIsEdit(false);
                setField04(periodValue);

            } else {

                setIsEdit(true);

                // setDisableUPN(dU => true)
                const getFormData = async () => {
                    await base.getAll("ManageData/ccrecon/" + id).then((res) => {
                        setField01(res.fromCC !== null ? res.fromCC : "");
                        setField02(res.toCC !== null ? res.toCC : "");
                        setField03(res.reason);
                        setField04(res.period);
                        setField05(res.value);
                        setField06(res.reason1 !== null ? res.reason1 : "");
                        // setField07(res.lastLogOn);
                        setField08(res.reason2 !== null ? res.reason2 : "");
                        setLoading(false)
                    })
                }

                getFormData();
            }

            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getData()
    }, [id, periodValue])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}>
                    {isEdit ?
                        <>
                            <div className="page-header">
                                Edit Reconciliation Item
                            </div>
                        </>
                        :
                        <>
                            <div className="page-header">
                                Create Reconciliation Item
                            </div>
                        </>
                    }
                </ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">From</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="fromCC" id="fromCC" className='input-text' value={field01} onChange={handleChange} >
                                            <option className='input-text' value="">

                                            </option>
                                            <option className='input-text' value="CC_MW">
                                                Mike Whale (CC_MW)
                                            </option>
                                            <option className='input-text' value="CC_LW">
                                                Lawrence Whale (CC_LW)
                                            </option>
                                            <option className='input-text' value="CC_LP">
                                                Laura Purser (CC_LP)
                                            </option>
                                            <option className='input-text' value="CC_AS">
                                                Alex Shepherd (CC_AS)
                                            </option>
                                            <option className='input-text' value="CC_KM">
                                                Katherine McNulty (CC_KM)
                                            </option>
                                            <option className='input-text' value="CC_OHD">
                                                Overhead (CC_OHD)
                                            </option>
                                            <option className='input-text' value="CC_WEB">
                                                Web (CC_WEB)
                                            </option>
                                        </Input>

                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">To</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="toCC" id="toCC" className='input-text' value={field02} onChange={handleChange} >
                                            <option className='input-text' value="">

                                            </option>
                                            <option className='input-text' value="CC_MW">
                                                Mike Whale (CC_MW)
                                            </option>
                                            <option className='input-text' value="CC_LW">
                                                Lawrence Whale (CC_LW)
                                            </option>
                                            <option className='input-text' value="CC_LP">
                                                Laura Purser (CC_LP)
                                            </option>
                                            <option className='input-text' value="CC_AS">
                                                Alex Shepherd (CC_AS)
                                            </option>
                                            <option className='input-text' value="CC_KM">
                                                Katherine McNulty (CC_KM)
                                            </option>
                                            <option className='input-text' value="CC_OHD">
                                                Overhead (CC_OHD)
                                            </option>
                                            <option className='input-text' value="CC_WEB">
                                                Web (CC_WEB)
                                            </option>
                                        </Input>

                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Justificaiton*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="reason" id="reason" placeholder="" defaultValue={field03} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Period*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="period" id="period" placeholder="" value={field04} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Value*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="value" id="value" placeholder="" value={field05} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Addition 1</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="reason1" id="reason1" placeholder="" value={field06} onChange={handleChange} />
                                    </Col>
                                </Row>

                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Addition 2</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="reason2" id="reason2" placeholder="" value={field08} onChange={handleChange} />
                                    </Col>
                                </Row>



                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button>
                            {isEdit &&
                                <Button color="success" size="sm" className="btn-text b-width" onClick={() => deleteItem()}>Delete</Button>
                            }
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default TasksModal
