import React, { useEffect } from 'react';
import '../../App.css';
import { Route, Routes, } from 'react-router-dom'
import AccessDenied from './accessDenied.js';
import routeConfig from './routeConfig.js';
import NavbarLoggedIn from '../shared/navbarLoggedIn.js';
import { useUserContext } from '../context/userContext.js';

const AuthenticatedRoutes = () => {

    useEffect(() => {
        const metaTag = document.querySelector('meta[name="viewport"]');

        if (metaTag) metaTag.remove(); // Remove previous meta tag if exists
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = 'viewport';
        newMetaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
        document.head.appendChild(newMetaTag);


        // Cleanup: Remove meta tag when component unmounts or changes
        return () => {
            if (metaTag) metaTag.remove();
        };
    }, []);

    const { user } = useUserContext();
    return (
        <>
            {user.length !== 0 && (
                <>
                    <NavbarLoggedIn loggedInName={`${user.firstName} ${user.lastName}`} userLevel={user.userLevel} />
                    <div style={{ paddingTop: '70px', overflowX: "hidden" }}>
                        <Routes>
                            {routeConfig.map(({ path, element, access }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={access.includes(user.userLevel) ? element : <AccessDenied />}
                                />
                            ))}
                        </Routes>
                    </div>
                </>
            )
            }
        </>
    );
}

export default AuthenticatedRoutes;
