import React, { useEffect, useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import TextOnImage from '../textOnImage'
import image from '../../../assets/images/cables.jpg'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import base from '../../../services/naBaseService'
import { useParams } from 'react-router-dom';

const Bespoke = () => {

    const [blogDetail, setBlogDetail] = useState([])
    const [blog, setBlog] = useState([])
    const { blogId } = useParams();

    useEffect(() => {
        document.title = 'Custom software development | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Discover the advantages of custom software development. Explore how bespoke solutions offer greater efficiencies compared to off-the-shelf software.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Custom software development, bespoke software development, bespoke systems, flexibile software, efficiency software, tailored software, cost-effective software';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="font-white">
            Custom software
        </span>
    )

    useEffect(() => {

        const promises = [
            base.getAll(`blog/qry/${blogId}`),
            base.getAll(`blog/detail/qry/${blogId}`),
        ];

        Promise.all(promises)
            .then(([res, detail]) => {
                setBlog(res);
                setBlogDetail(detail)
            });

    }, [blogId]);

    return (
        <>
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />
            <div className="container-large">
                {/* <Row className="text-center pb-5">
                    <Col md="4">
                        <span className="">
                            <IoShirtOutline size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Tailored to specific needs</h5>
                        <p className="text-center sub-text">
                            Custom software is designed to meet the specific needs and requirements of a particular organization, rather than being a one-size-fits-all solution. This means that it can be designed to solve specific business problems and workflows.
                        </p>
                    </Col>
                    <Col md="4">
                        <span className="">
                            <AiOutlineLineChart size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Increased efficiency</h5>
                        <p className="text-center sub-text">
                            Custom software can automate tasks and processes, reducing the time and effort required to complete them. This can lead to increased efficiency and productivity for the organization.
                        </p>
                    </Col>
                    <Col md="4">
                        <span className="">
                            <AiOutlinePoundCircle size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Cost-effectiveness</h5>
                        <p className="text-center sub-text">
                            While the initial cost of custom software may be higher than off-the-shelf solutions, in the long run, it can be a more cost-effective option as it provides a more efficient and effective solution for the organization.                                    </p>
                    </Col>

                </Row>
                <Row className="text-center pb-5">
                    <Col md="4">
                        <span className="">
                            <IoScaleOutline size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Scalability</h5>
                        <p className="text-center sub-text">
                            Custom software can be designed to accommodate future growth and change, ensuring that it can continue to meet the needs of the organization as it grows.                                    </p>

                    </Col>
                    <Col md="4">
                        <span className="">
                            <IoChatbubbleEllipsesOutline size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Better support</h5>
                        <p className="text-center sub-text">
                            Because custom software is developed specifically for a particular organization, the organization has direct access to the development team, who can provide tailored support and maintenance services.                                    </p>
                    </Col>
                    <Col md="4">
                        <span className="">
                            <AiOutlineApi size={100} color="#0275d8" />
                        </span>
                        <h5 className="text-center my-3 head-text">Better data integration</h5>
                        <p className="text-center sub-text">
                            Custom software can be integrated with the organization's existing systems, ensuring that data is accurate, up-to-date, and easily accessible.
                        </p>
                    </Col>
                </Row> */}

                <Link to="/blog" >
                    <Button className="mt-3 btn-white">
                        <div className="d-flex">
                            <div >
                                <IoArrowBack size={25} />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ paddingTop: "1px" }}>Blog</div>
                        </div>
                    </Button>

                </Link>
                <div className="heading-block">
                    <h2 className="content-heading">
                        {blog.heading}
                    </h2>
                </div>
                <div className="mb-5">
                    <p style={{ textAlign: "left" }} className="content-body">
                        {blog.subheading}
                    </p>
                    {blogDetail.map((item, index) => {
                        return (
                            <React.Fragment key={`blog_detail${index}`}>
                                <h3 className="blog-heading mt-5">
                                    {item.heading}
                                </h3>
                                <p style={{ textAlign: "left" }} className="content-body" dangerouslySetInnerHTML={{ __html: item.bodyText }}>

                                </p>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>

            {/* <div className="container-large">

                <div className="row fw-light">
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Tailored to specific needs</div>
                                <p className="small lh-1-5 mb-0">
                                    Bespoke software is designed to meet the specific needs and requirements of a particular organization, rather than being a one-size-fits-all solution. This means that it can be designed to solve specific business problems and workflows, making it much more efficient and effective for the organization.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Increased efficiency</div>
                                <p className="small lh-1-5 mb-0">
                                    Bespoke software can automate tasks and processes, reducing the time and effort required to complete them. This can lead to increased efficiency and productivity for the organization                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Competitive advantage</div>
                                <p className="small lh-1-5 mb-0">
                                    By having software that is unique to the organization, it can give the organization a competitive advantage in its market.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Better data integration</div>
                                <p className="small lh-1-5 mb-0">
                                    Bespoke software can be integrated with the organization's existing systems, ensuring that data is accurate, up-to-date, and easily accessible.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Scalability</div>
                                <p className="small lh-1-5 mb-0">
                                    Bespoke software can be designed to accommodate future growth and change, ensuring that it can continue to meet the needs of the organization as it grows.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Better support</div>
                                <p className="small lh-1-5 mb-0">
                                    Because bespoke software is developed specifically for a particular organization, the organization has direct access to the development team, who can provide tailored support and maintenance services.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mx-auto mb-5">
                        <div className="d-flex flex-column h-100 hover-shadow-lg border">
                            <div className="px-6 py-7">
                                <div className="d-inline-block position-relative square-sm rounded-circle bg-bp-CO2-blue-50 text-dark mb-4">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" role="img" className="absolute-center"><g fill="none" fill-rule="evenodd"><path fill="none" d="M0 0h32v32H0z"></path><path d="M7 7.6h18v12h-8.4l-5.4 4.2v-4.2H7z" stroke="currentColor"></path></g></svg>
                                </div>
                                <div className="fw-normal mb-2">Cost-effectiveness</div>
                                <p className="small lh-1-5 mb-0">
                                    While the initial cost of bespoke software may be higher than off-the-shelf solutions, in the long run, it can be a more cost-effective option as it provides a more efficient and effective solution for the organization.                                    </p>
                            </div>
                        </div>
                    </div>


                </div>

            </div> */}
        </>
    );
}

export default Bespoke;
