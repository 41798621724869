import axios from "./naAxiosInstance";

const base = {

    getAll: async (url) => {
        const response = await axios.get(url,
            { withCredentials: false });
        return response.data;
    },

    get: async (url, id) => {
        const response = await axios.get(url + '/' + id,
            { withCredentials: false });
        return response.data;
    },

    qry: async (url, id) => {
        const response = await axios.get(url + '/' + id,
            { withCredentials: false });
        return response.data;
    },
}

export default base;