import React from "react";
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineRead } from 'react-icons/ai'
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import Spinner from '../../shared/loading'
import BlogModal from "./blogModal"

const BlogManagement = () => {
    const gridRef = useRef();
    const [blogColDef, setBlogColDef] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [blogID, setBlogID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const [create, setCreate] = useState(false)

    useEffect(() => {
        base.getAll("managedata/blog/qry").then((res) => {
            setBlogsData(res)
            setTimeout(() => {
                setExpLoading(false)
            }, [1000])
        });
    }, [saveItem]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBlogColDef([
            {
                headerName: "Heading",
                field: "heading",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "Subheading",
                field: "subheading",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "Author",
                field: "author",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "Display",
                field: "display",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                // name: 'Post date',
                // selector: row => {
                //     return (
                //         <div className="text-left">
                //             {row.post_date !== null &&
                //                 row.post_date
                //                     ?.substring(0, 4) +
                //                 "-" +
                //                 row.post_date
                //                     ?.substring(5, 7) +
                //                 "-" +
                //                 row.post_date?.substring(8, 10)}
                //         </div>
                //     );
                // },
                // sortable: true,
                // wrap: false,
                // allowOverflow: false,
                // minWidth: '100px',
                // maxWidth: '100px',
                // hide: false

                headerName: "Post date",
                field: "post_date",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "Read time",
                field: "read_time",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                // name: 'Up date',
                // selector: row => {
                //     return (
                //         <div className="text-left">
                //             {row.up_date !== null &&
                //                 row.up_date
                //                     ?.substring(0, 4) +
                //                 "-" +
                //                 row.up_date
                //                     ?.substring(5, 7) +
                //                 "-" +
                //                 row.up_date
                //                     ?.substring(8, 10) +
                //                 " " +
                //                 row.up_date?.substring(11, 19)}
                //         </div>
                //     );
                // },
                // sortable: true,
                // wrap: false,
                // allowOverflow: false,
                // minWidth: '100px',
                // maxWidth: '100px',
                // hide: false

                headerName: "Updated",
                field: "up_date",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "User",
                field: "up_User",
                suppressMovable: true,
                maxWidth: 300
            },
        ]);
    }, []);

    const openModal = (type) => {
        if (type === "create") {
            setCreate(true)
            setBlogID("")
        } else if (type === "edit") {
            setCreate(false)
        }
        setShowModal(!showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBlogID(selectedRows[0].id);
            openModal("edit");
        }
    };

    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        <AiOutlineRead size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">Blog Management</span>
                    </Col>
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="light border-dark" className="ps-3 pe-3 me-1" style={{ height: "38px" }} onClick={() => openModal("create")}>
                            Create blog
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <div className="container-fluid">
                <Row>
                    <Col xs="12">
                        {expLoading ?
                            <CardBody style={{ height: "750px" }}>
                                <div className="row align-items-center">
                                    <div className="text-center" >
                                        <Spinner />
                                    </div>
                                </div>
                            </CardBody>
                            :
                            <>
                                <CardBody style={{ height: "680px" }}>
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={blogsData}
                                            columnDefs={blogColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            rowSelection={'single'}
                                            paginationPageSize={20}
                                            onRowDoubleClicked={onSelectionChanged}
                                        >
                                        </AgGridReact>
                                    </div>
                                </CardBody>
                            </>
                        }
                    </Col>
                </Row >
            </div>
            {showModal && <BlogModal id={blogID} setID={setBlogID} closeModal={closeModal} setCreate={setCreate} showModalForm={true} setSaveItem={setSaveItem} create={create} />}
        </>
    )
}

export default BlogManagement
